import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "field",
    "side"
  ]

  static values = {
    disabled: Boolean,
  }

  get unselectedSide() {
    return this.application.getControllerForElementAndIdentifier(this.sideTargets[0], 'list-builder-side')
  }

  get selectedSide() {
    if (this.sideTargets.length == 1) return this.unselectedSide
    return this.application.getControllerForElementAndIdentifier(this.sideTargets[1], 'list-builder-side')
  }

  toggleAll(event) {
    for (let option of this.fieldTarget.options) {
      option.selected = true
    }

    const sourceSide = event.params.selected ? this.selectedSide : this.unselectedSide
    const targetSide = event.params.selected ? this.unselectedSide : this.selectedSide
    sourceSide.itemTargets.forEach(item => targetSide.add(item))

    this.updateCounts()
  }

  toggleSelection(event) {
    const value = event.params.value
    for (let option of this.fieldTarget.options) {
      if (option.value != value) continue

      const targetSide = option.selected ? this.unselectedSide : this.selectedSide
      targetSide.add(event.currentTarget)
      option.selected = !option.selected

      break
    }

    this.updateCounts()
  }

  updateCounts() {
    this.unselectedSide.updateCount()
    this.selectedSide.updateCount()
  }
}
