import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "count",
    "list",
    "item",
  ]

  add(item) {
    this.listTarget.appendChild(item)
  }

  updateCount() {
    if (!this.hasCountTarget) return
    this.countTarget.innerHTML = this.listTarget.childElementCount
  }
}
